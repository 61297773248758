import '../css/main.scss';

import 'jquery-validation';
$.validator.unobtrusive = require('jquery-validation-unobtrusive');
import 'jssocials';

/* Bootstrap modules - comment out as required */
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'lazysizes';

import '../../../Components/Base/BaseComponent/Content/validation/mustbetrue';

import '../../../Components/Base/BaseComponent/Content/components/CKReadMore';
import '../../../Components/Base/BaseComponent/Content/components/MapOffices';
import '../../../Components/Base/BaseComponent/Content/components/GenericAutocomplete';

import '../../../Components/Base/BaseComponent/Content/components/AjaxPage';
import '../../../Components/Base/BaseComponent/Content/components/BotForm';
import { InitialiseForElement as AjaxFormInitialise } from '../../../Components/Base/BaseComponent/Content/components/PartialHost';
import { Initialise as RecaptchaFormInitialise } from '../../../Components/Base/BaseComponent/Content/components/RecaptchaForm';
import '../../../Components/Base/BaseComponent/Content/components/StyleSwitcher';

import { Initialize as InitializePromoSliders } from '../../../Components/Base/BaseComponent/Content/components/PromoSlider';

document.body.classList.remove("no-js");
document.body.classList.add("js");

$(function ()
{
    $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event)
    {
        event.preventDefault();
        event.stopPropagation();

        const parent = $(this).parent(".dropdown");
        const prevStatus = parent.hasClass("show");

        parent.toggleClass("show");
        $(this).siblings(".dropdown-menu").toggleClass("show");

        if (!prevStatus)
        {
            const root = $(this).parentsUntil("nav", ".navbar-nav");
            if (root.length !== 1)
            {
                return;
            }

            const rootElem = root[0];
            const other = $(rootElem).find(".dropdown.show");

            other.each((_idx, elem) =>
            {
                const isParentOrElement = $(elem).has(parent[0]).length !== 0 || parent[0] === elem;
                if (!isParentOrElement)
                {
                    $(elem).removeClass("show");
                    $(elem).find(".dropdown-menu").removeClass("show");
                }
            });
        }
    });

    $("[data-toggle=\"popover\"]").popover();

    const forms = document.querySelectorAll("form[data-ajax]");
    for (let i = 0; i < forms.length; i++)
    {
        if ((forms[i] as HTMLFormElement).dataset.recaptcha)
        {
            RecaptchaFormInitialise(forms[i] as HTMLFormElement);
        }
        else
        {
            AjaxFormInitialise(forms[i] as HTMLFormElement);
        }
    }

    $("[data-socials]").jsSocials({
        showLabel: false,
        showCount: false,
        shares: ["email", "facebook", "twitter", "linkedin"]
    });

    $("#bsm-redirect-modal").modal();

    $(".safety-exit").on("click", function ()
    {
        const urlSky = "https://news.sky.com/uk";
        const urlBBC = "https://www.bbc.co.uk/news";

        const newWindow = window.open(urlBBC, "_blank");
        newWindow?.focus();
        window.location.replace(urlSky);
    });

    InitializePromoSliders();
});